import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
} from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PHONES_BY_COUNTRY } from "@/lib/constants";

import { Section } from "./Section";

import styles from "./Footer.module.css";

const Footer: React.FunctionComponent = () => {
  const [country, setCountry] = useState("CL");
  useEffect(() => {
    (async () => {
      const req = await fetch("https://extreme-ip-lookup.com/json/");
      const { countryCode } = await req.json();
      setCountry(countryCode);
    })();
  }, []);
  return (
    <footer>
      <Section className={styles.footer}>
        <Row gutter={[24, 48]}>
          <Col md={8} xs={24} className={styles.footerButtonLogo}>
            <Space direction="vertical" size={42}>
              <Link href="/">
                <a aria-label="Klog.co">
                  <Image
                    alt="Klog.co"
                    layout="fixed"
                    width={150}
                    height={39}
                    className={styles.logo}
                    src="/static/images/logos/logo-klog--white.svg"
                  />
                </a>
              </Link>
              <h3 className={styles.slogan}>
                <FormattedMessage id="footerSlogan" />
              </h3>
              <Link href="/contactUs">
                <Button danger className={styles.footerButton} size="large">
                  <FormattedMessage id="footerContact" />
                </Button>
              </Link>
            </Space>
          </Col>
          <Col md={16}>
            <Row gutter={[24, 60]}>
              {/* <Col lg={6} md={8} sm={12} xs={24}>
                <h3 className={styles.footerTitle}>
                  <FormattedMessage id="platform" />
                </h3>
                <Space size={12} direction="vertical">
                  <Link href="/">
                    <a className={styles.footerItem}>
                      <FormattedMessage id="home" />
                    </a>
                  </Link>
                  <Link href="/">
                    <a className={styles.footerItem}>
                      <FormattedMessage id="enter" />
                    </a>
                  </Link>
                </Space>
              </Col> */}
              <Col lg={6} md={8} sm={12} xs={24}>
                <h3 className={styles.footerTitle}>
                  <FormattedMessage id="aboutUs" />
                </h3>
                <Space size={12} direction="vertical">
                  <a href="https://blog.klog.co" className={styles.footerItem}>
                    <FormattedMessage id="newsBlog" />
                  </a>
                  <Link href="/aboutUs">
                    <a className={styles.footerItem}>
                      <FormattedMessage id="aboutUs" />
                    </a>
                  </Link>
                  <a
                    href="https://prensa.klog.co"
                    className={styles.footerItem}
                  >
                    <FormattedMessage id="pressCenter" />
                  </a>
                </Space>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <h3 className={styles.footerTitle}>
                  <FormattedMessage id="contact" />
                </h3>
                <Space size={12} direction="vertical">
                  <Link href="/contactUs">
                    <a className={styles.footerItem}>
                      <FormattedMessage id="contact" />
                    </a>
                  </Link>
                  {country === "PE" && (
                    <Link href="/complaints">
                      <a className={styles.footerItem}>
                        <FormattedMessage id="complaintsAndClaims" />
                      </a>
                    </Link>
                  )}

                  <p>
                    <FormattedMessage id="infoMail" />
                  </p>
                </Space>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <h3 className={styles.footerTitle}>
                  <FormattedMessage id="klogWarehousing" />
                </h3>
                <Space size={12} direction="vertical">
                  <Link href="/warehousing">
                    <a className={styles.footerItem}>
                      <FormattedMessage id="aboutKLW" />
                    </a>
                  </Link>
                  <Link href="/contactUs">
                    <a className={styles.footerItem}>
                      <FormattedMessage id="contactKW" />
                    </a>
                  </Link>
                </Space>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <h3 className={styles.footerTitle}>
                  <FormattedMessage id="whereWeAre" />
                </h3>
                <Space size={12} direction="vertical">
                  <p className={styles.footerItem}>
                    <FormattedMessage id="klogAddress" />
                  </p>
                  <p className={styles.footerItem}>
                    <FormattedMessage id="klogPeruAddress" />
                  </p>
                  <p className={styles.footerItem}>
                    <FormattedMessage id="klogMexicoAddress" />
                  </p>
                  <p className={styles.footerItem}>
                    <FormattedMessage id="klogBoliviaAddres" />
                  </p>
                </Space>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <h3 className={styles.footerTitle}>
                  <FormattedMessage id="ourSocial" />
                </h3>
                <Space size={12} direction="vertical">
                  <a
                    rel="noopener noreferrer"
                    className={styles.footerItem}
                    href="https://www.facebook.com/KLogco-171532386636673"
                    target="_blank"
                  >
                    <FacebookFilled /> <FormattedMessage id="facebook" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    className={styles.footerItem}
                    href="https://www.linkedin.com/company/klog-chile"
                    target="_blank"
                  >
                    <LinkedinFilled /> <FormattedMessage id="linkedin" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    className={styles.footerItem}
                    href="https://www.instagram.com/klog.co"
                    target="_blank"
                  >
                    <InstagramFilled /> <FormattedMessage id="instagram" />
                  </a>
                </Space>
              </Col>
              <Col lg={6} md={8} sm={12} xs={24}>
                <h3 className={styles.footerTitle}>
                  <FormattedMessage id="contactPhones" />
                </h3>
                <Space size={12} direction="vertical">
                  {Object.entries(PHONES_BY_COUNTRY).map(
                    ([city, phoneNumber]) =>
                      phoneNumber.map((phone) => (
                        <p>
                          {phone} ({city})
                        </p>
                      ))
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>
      <Section className={styles.copy}>
        <Row>
          <div>
            <FormattedMessage
              id="footerTextCredits"
              values={{ year: new Date().getFullYear() }}
            />
          </div>
          {" | "}
          <div>
            <a
              rel="noopener noreferrer"
              className={styles.privacyText}
              href="https://landing.klog.co/aviso-y-pol%C3%ADtica-de-privacidad"
              target="_blank"
            >
              <FormattedMessage id="privacyText" />
            </a>
          </div>
        </Row>
      </Section>
    </footer>
  );
};

export default Footer;
