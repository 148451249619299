import { Col, Row } from "antd";
import React from "react";

import styles from "./Section.module.css";

export const Section = ({ children, className = "", ...rest }) => {
  return (
    <div className={`${className} ${styles.section}`}>
      <Row justify="center" {...rest}>
        <Col xl={20} lg={22}>
          {children}
        </Col>
      </Row>
    </div>
  );
};
